import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutMe = () => (
  <Layout>
    <SEO title="About Me" />
    <p>
        Hi, my name is Chris and in-game my character's name is Silverkitty (US-Tichondrius). I've been playing WoW since the beginning and PvP has always been my main focus. I've achieved rank 1 on a restoration shaman and gladiator on several classes. At the time of writing this, I'm currently the highest rated alliance resto shaman in both 2 vs 2 and 3 vs 3 arena combat (North America region). I used to create content for skill-capped and I've played at the top of the ladder with and against the pros for many years! Many people note my "off-meta" race/stat choices or sometimes even team compositions. I'm a huge advocate for playing for fun and I'm here to try to convince you to do the same while also empowering you to be your best!
    </p>

  </Layout>
)

export default AboutMe
